import * as React from 'react';
import { PageLayout } from 'components';
import { graphql } from 'gatsby';

const NotFoundPage = ({ data }: any) => {
  const { banner } = data.strapiGlobal;
  return (
    <PageLayout
      title="404: Not found"
      hasContainerClass
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
    >
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageLayout>
  );
};

export default NotFoundPage;
export const query = graphql`
  query NotFoundPageQuery {
    strapiGlobal {
      banner: defaultBanner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
